//
// _authentication.scss
//

.auth-page-wrapper {
    .auth-page-content {
        padding-bottom: $footer-height;
        position: relative;
        z-index: 2;
        width: 100%;
    }
    .footer {
        left: 0;
        background-color: transparent;
        color: $body-color;
    }
}

.auth-one-bg-position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 380px;

    @media (max-width: 575.98px) {
        height: 280px;
    }
}

.auth-one-bg {
    background-image: url('../../images/auth-one-bg.jpg');
    background-position: center;
    background-size: cover;

    .bg-overlay {
        background: linear-gradient(to right, darken($primary, 6%), $primary);
        opacity: 0.9;
    }

    .shape {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
        > svg {
            width: 100%;
            height: auto;
            fill: var(--#{$variable-prefix}body-bg);
        }
    }
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
    input[type='text'] + .btn .ri-eye-fill {
        &:before {
            content: '\ecb6';
        }
    }
}

.particles-js-canvas-el {
    position: relative;
}

// signin card title

.signin-other-title {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        border-top: 1px dashed $border-color;
        top: 10px;
    }

    .title {
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: $card-bg;
        padding: 2px 16px;
    }
}

// Auth cover

.auth-bg-cover {
    background: linear-gradient(-45deg, $primary 50%, $success);

    > .bg-overlay {
        background-image: url('../../images/cover-pattern.png');
        background-position: center;
        background-size: cover;
        opacity: 1;
        background-color: transparent;
    }

    .footer {
        color: rgba($white, 0.5);
    }
}

//passowrd validations
#password-contain {
    display: none;

    p {
        padding-left: 13px;

        &.valid {
            color: $success;

            &::before {
                position: relative;
                left: -8px;
                content: '✔';
            }
        }

        &.invalid {
            color: $danger;

            &::before {
                position: relative;
                left: -8px;
                content: '✖';
            }
        }
    }
}
