@import '../scss/icons.scss';
@import '../scss/config/default/bootstrap.scss';
@import '../scss/config/default/app.scss';
@import '../scss/config/default/custom.scss';
@import './shepherd.css';
@import './mermaid.min.css';

//RTL
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";

//Rigthsidebar offcanvas
.offcanvas-header-dark {
    .btn-close {
        filter: invert(1) grayscale(100%) brightness(200%);
    }
}

body {
    * {
        outline: none;
    }
}

.noUi-horizontal .noUi-handle {
    height: 16px !important;
    width: 16px !important;
    border-radius: 50% !important;
    right: -10px !important;
    top: -7px !important;
}

.noUi-connect,
.noUi-horizontal {
    height: 2px !important;
}

.noUi-handle {
    background: #0ab39c !important;
    border: 2px solid var(--vz-card-bg) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.noUi-target {
    background: #fafafa !important;
    border-radius: 4px !important;
    border: 1px solid #d3d3d3 !important;
    background-color: var(--vz-light) !important;
    border-color: var(--vz-light) !important;
    box-shadow: none !important;
    height: 4px !important;
}

.dropzone .dz-message {
    text-align: center;
}

// //Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    padding: 5px !important;
    font-size: 12px !important;
    margin-right: 2px;
}

.fc-event {
    border: none !important;
}

@include media-breakpoint-down(sm) {
    .fc-toolbar {
        display: block !important;
        text-align: center;

        .fc-left,
        .fc-right,
        .fc-center {
            float: none;
            display: block;
            clear: both;
            margin: 10px 0;
        }
    }

    .fc {
        .fc-toolbar {
            > * {
                > * {
                    float: none;
                }
            }
        }
    }

    .fc-today-button {
        display: none;
    }
}

.flatpickr-calendar.open {
    z-index: 1090 !important;
}

.upcoming-scheduled .flatpickr-input[readonly] {
    display: none;
}

.ql-container.ql-snow {
    height: 250px;
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: #405189 !important;
}

.Toastify__toast {
    min-height: 43px !important;
    max-height: 43px !important;
}

@each $color, $value in $theme-colors {
    .simplebar-track-#{$color} {
        .simplebar-scrollbar:before {
            background: $value !important;
        }
    }
}

// chat
.chat-conversation .chat-list .message-box-drop {
    .nav-btn {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;
    }
}

// authentication

#tsparticles {
    canvas {
        position: absolute !important;
    }
}

.alert-msg-show {
    right: 24px;
    top: 40px;
    z-index: 1005;
}

.TUEFO {
    padding: 0 5px !important;
}

.rdt_TableHead {
    font-size: 13px;
    font-weight: 600;
}

.row-checkbox .form-switch-md .form-check-input {
    left: -7px !important;
}

.css-g1d714-ValueContainer {
    height: 35px;
    overflow-y: auto !important;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}

.css-g1d714-ValueContainer::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.ut-post {
    background-color: #fff !important;
}

.ReactModal__Overlay {
    z-index: 9999 !important;
}
.gallery-thumb {
    cursor: pointer;
    width: 100%;
    height: 150px;
    background-position: center center;
    background-size: cover;
}
